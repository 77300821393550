import { NextPage } from 'next';
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Container } from 'styled-bootstrap-grid';
import { Heading, Text } from 'mdlkit';
import WebLayout from '../components/layout/www/WebLayout';
import { NextHyperlink } from '../components/shared';
import { HOME_LINK } from '../constants/home';
import logError from '../utils/errors/logError';

const NotFound: NextPage = () => {
  const router = useRouter();

  useEffect(() => {
    try {
      throw new Error('Page Not Found');
    } catch (error) {
      const { asPath, query } = router;
      logError(error, { path: asPath, query });
    }
  }, []);

  return (
    <WebLayout>
      <Container>
        <Heading as="h1" pt="xl">
          Whoops! Error 404
        </Heading>
        <Text py="sm">This page does not exist.</Text>
        <NextHyperlink href={HOME_LINK}>Return to MDLIVE.com</NextHyperlink>
      </Container>
    </WebLayout>
  );
};
export default NotFound;
